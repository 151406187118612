import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "flex-start",
    padding: 12,
    backgroundColor: "#F8F8F8",
    borderRadius: 4,
    marginBottom: 24,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 4,
    paddingTop: 4,
    flex: 1,
  },
  header: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  headerName: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 28,
    height: 28,
  },
  timeLabel: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 2,
  },
  timeIcon: {
    color: "#ffffff",
    fontSize: 12,
  },
  fileInfo: {
    alignItems: "center",
    marginTop: theme.spacing(1),
    backgroundColor: "#ECECEC",
    borderRadius: 4,
    padding: 8,
  },
  name: {
    fontSize: 14,
    color: "#747474",
    fontWeight: 600,
    paddingTop: 3,
  },
  date: {
    fontSize: 14,
    color: "#747474",
    fontWeight: 400,
  },
  message: {
    fontSize: 14,
    color: "#747474",
    fontWeight: 400,
  },
  fileDetailsName: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 4,
  },
  fileDetails: {
    fontSize: 10,
    fontWeight: 400,
    color: "#747474",
  },
  fileTimestamp: {
    fontSize: 10,
    fontWeight: 400,
    backgroundColor: "#4F98BC",
    borderRadius: 50,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    color: "#FFF",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 4,
  },
  cardRight: {
    marginRight: "15%",
  },
  cardLeft: {
    marginLeft: "15%",
  },
  updateCommentContainer: {
    borderRadius: 4,
    border: "1px solid #D9D9D9",
    background: "#FFF",
    padding: 10,
  },
  updateCommentButton: {
    display: "flex",
    width: "auto",
    height: 32,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    flexShrink: 0,
    alignSelf: "stretch",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    background: "#4F98BC",
    color: "#ffffff",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4F98BC",
    },
    "&:active": {
      background: "#4F98BC",
    },
  },
  cancelCommentButton: {
    display: "flex",
    width: 92,
    height: 32,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    border: "1px solid  #4F98BC",
    background: "#FFF",
    color: "#4F98BC",
    textTransform: "capitalize",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
  },
  paddingMenuIcon: {
    paddingLeft: 20,
  },
  inputUpdateContainer: {
    display: "flex",
    flexDirection: "column",
  },
  updateButtonsContainer: {
    paddingTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
  },
  iconBackground: {
    backgroundColor: "#f0f0f0",
    padding: 2,
    borderRadius: 4,
  },
  menuText: {
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    paddingLeft: 8,
  },
  menuIcon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    borderRadius: 4,
    background: "var(--Grey-200, #ECECEC)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarName: {
    color: "#747474",
    backgroundColor: "#dfdfdf",
    fontSize: 12,
    fontWeight: "bold",
    width: 32,
    height: 32,
  },
}));

export default useStyles;
