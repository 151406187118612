import React, { useState, useContext } from "react";
import * as classNames from "classnames";
import {
  Typography,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Input,
  Button,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { dateFormatField } from "components/util/timeFormat";
import { permissionAdmin, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "./styles";

const WorkticketComment = ({
  id,
  externalId,
  user,
  time,
  date,
  message,
  fileName,
  fileOwner,
  fileType,
  fileSize,
  showMenuOptions = true,
  onUpdateComment,
  onDeleteComment,
}) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedComment, setUpdatedComment] = useState(message);
  const usr = JSON.parse(localStorage.getItem("user"));

  const isOwner = parseInt(user.id) === usr.id;

  const handleMenuClick = (event, commentId) => {
    setAnchorEl(event.currentTarget);
    setSelectedCommentId(commentId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditComment = () => {
    setEditMode(true);
    handleMenuClose();
  };

  const handleUpdateComment = () => {
    if (onUpdateComment) {
      onUpdateComment(selectedCommentId, updatedComment);
    }
    setEditMode(false);
  };

  const handleDeleteComment = () => {
    if (onDeleteComment) {
      onDeleteComment(selectedCommentId);
    }
  };

  return (
    <Box
      className={classNames(classes.card, {
        [classes.cardRight]: !isOwner,
        [classes.cardLeft]: isOwner,
      })}
    >
      <Box>
        {user.avatar ? (
          <Avatar className={classes.avatar} src={user.avatar} />
        ) : (
          <Avatar className={classes.avatarName}>
            {user.name
              .split(" ")
              .map((word) => word.charAt(0))
              .join("")}
          </Avatar>
        )}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Box className={classes.headerName}>
            <Typography variant="h3" className={classes.name}>
              {user.name}
            </Typography>
            {time && (
              <Box className={classes.fileTimestamp}>
                <AccessTimeIcon className={classes.timeIcon} />
                <Typography variant="h3" className={classes.timeLabel}>
                  {time}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.dateContainer}>
            <Typography variant="h3" className={classes.date}>
              {dateFormatField(date)}
            </Typography>

            {showMenuOptions && (externalId === null || externalId === "") && (
              <Box className={classes.paddingMenuIcon}>
                {(hasPermission(permissionAdmin.COMMENTS_MANAGE, permissions) ||
                  usr.id === parseInt(user.id)) && (
                  <MoreHorizIcon
                    onClick={(event) => handleMenuClick(event, id)}
                  />
                )}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {usr.id === parseInt(user.id) ? (
                    <MenuItem onClick={handleEditComment}>
                      <Box className={classes.menuItem}>
                        <Box className={classes.menuIcon}>
                          <EditIcon fontSize="inherit" />
                        </Box>
                        <Typography
                          variant="caption"
                          className={classes.menuText}
                        >
                          Edit
                        </Typography>
                      </Box>
                    </MenuItem>
                  ) : null}

                  <MenuItem onClick={handleDeleteComment}>
                    <Box className={classes.menuItem}>
                      <Box className={classes.menuIcon}>
                        <DeleteIcon fontSize="inherit" />
                      </Box>
                      <Typography
                        variant="caption"
                        className={classes.menuText}
                      >
                        Delete
                      </Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </Box>

        {editMode ? (
          <Box className={classes.inputUpdateContainer}>
            <Box className={classes.updateCommentContainer}>
              <Input
                disableUnderline
                multiline
                fullWidth
                value={updatedComment}
                onChange={(e) => setUpdatedComment(e.target.value)}
                className={classes.updateCommentInput}
              />
            </Box>
            <Box className={classes.updateButtonsContainer}>
              <Box mr={1}>
                <Button
                  className={classes.cancelCommentButton}
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              </Box>
              <Box ml={1}>
                <Button
                  className={classes.updateCommentButton}
                  onClick={handleUpdateComment}
                >
                  Update Comment
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography variant="h3" className={classes.message}>
            {message}
          </Typography>
        )}
        {fileName && (
          <Box className={classes.fileInfo}>
            <Typography variant="h3" className={classes.fileDetailsName}>
              {fileName}
            </Typography>
            <Typography variant="h3" className={classes.fileDetails}>
              {[fileOwner, fileType, fileSize].filter(Boolean).join(" | ")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WorkticketComment;
